import {
    trackGooglePromotionEvent_click,
    MerklisteKarusellProductClickedTracking,
    tracking_eecPromotionview,
    trackExternalBasketClick,
    trackGoogleAddToMerkliste_click,
    pushTrboId,
    trackIdLayerComplete,
    trackIdLayerReduced,
} from './GoogleTracking';
import { loginTracking } from './GoogleTracking';
import { trackNaviEvent_click } from './GoogleTracking';

declare var _trboq: any;
declare var _trbo: any;
import * as Cookies from 'js-cookie';

$(async () => {
    await tracking_eecPromotionview();
    await TrackLogin();
    await trackIdLayer();

    ///tracking event f�r den Cklick auf Title/Image in Karusell TOP Produkte
    $('body').on('click', '.topProductTracking', function() {
        var trackingParam = $(this).data('trackingevent');
        if (trackingParam) {
            trackGooglePromotionEvent_click(trackingParam);
        }
    });

    $('body').on('click', '.addToMerklisteTracking', function() {
        const productId = $(this).data('id');
        if (productId) {
            trackGoogleAddToMerkliste_click(productId);
        }
    });

    $('body').on('click', '.ffSponsored', function() {
        var trackingParam = $(this).data('trackingevent');
        if (trackingParam) {
            trackGooglePromotionEvent_click(trackingParam);
        }
    });

    $('body').on('click', '.similarProductTracking', function() {
        var trackingParam = $(this).data('trackingevent');
        if (trackingParam) {
            trackGooglePromotionEvent_click(trackingParam);
        }
    });

    $('body').on('click', '.merklisteTracking', function() {
        MerklisteKarusellProductClickedTracking();
    });

    $('body').on('click', '.probeAboTracking', function() {
        var id = parseInt($(this).data('productid'));
        const price = Number.parseFloat($(this).attr('data-price'));
        testAboTracking(id, price);
    });

    $('body').on('click', '.externalBasketLink', function() {
        const id = parseInt($(this).data('productid'));
        const eventAction = $(this).data('eventaction');
        const eventLabel = $(this).data('eventlabel');
        trackExternalBasketClick(id, eventLabel, eventAction);
    });

    $('body').on('click', '.trboClickTracking', function() {
        const campaignId = $(this).data('campaignid');
        const campaignName = $(this).data('campaignname');
        const moduleId = $(this).data('moduleid');
        const moduleName = $(this).data('modulename');
        const userType = $(this).data('usertype');
        const productId = $(this).data('productid');
        if (campaignId && moduleId && productId) {
            _trboq.push([
                'layerClick',
                {
                    moduleId: moduleId,
                    campaignId: campaignId,
                    productId: productId,
                },
            ]);
            _trbo.app.tracker.trackExternalClick(userType, campaignId, campaignName, moduleId, moduleName);
        }
    });

    $('body').on('click', '.trboBannerClickTracking', function() {
        const campaignId = $(this).data('campaignid');
        const moduleId = $(this).data('moduleid');
        const moduleName = $(this).data('modulename');
        const campaignName = $(this).data('campaignname');
        const usertype = $(this).data('usertype');
        if (campaignId && moduleId) {
            _trboq.push([
                'layerClick',
                {
                    moduleId: moduleId,
                    campaignId: campaignId,
                },
            ]);
            _trbo.app.tracker.trackExternalClick(usertype, campaignId, campaignName, moduleId, moduleName);
        }
    });

    $('body').on('click', '.googleTracking.promotionTracking', function(e) {
        var trackingParam = $(this).data('trackingevent');
        if (trackingParam) {
            trackGooglePromotionEvent_click(trackingParam);
        }
    });

    var timer;
    $(window).on('scroll', function() {
        if (timer) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(async function() {
            await tracking_eecPromotionview();
        }, 100);
    });

    $('.googleNaviLinkTracking').click(function() {
        var trackingParam = $(this).data('trackingevent');
        if (trackingParam) {
            trackNaviEvent_click(trackingParam);
        }
    });
});

/**
 * Tracking f�r "beck-online 14-Tage-Test"
 */
function testAboTracking(productId: number, price: number) {
    const priceValue = price * 12;
    const fixedPriceValue = priceValue.toFixed(2);
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'click on bo button',
        eventLabel: `click_bo_${productId}`,
        eventValue: fixedPriceValue,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: [
                'product data',
                'click on bo button' + '/' + `click_bo_${productId}`,
                Number.parseFloat(fixedPriceValue),
                'd',
            ],
            rqtype: 'hiddenpi',
        },
    });
}

async function TrackLogin() {
    const loggedInPageType = Cookies.get('LoggedInPageType');
    const loggedInResult = Cookies.get('LoggedInResult');

    if (loggedInPageType && loggedInResult) {
        await loginTracking(loggedInPageType, loggedInResult);
    }

    const eId = Cookies.get('eId');
    if (eId) {
        await pushTrboId(eId);
        Cookies.remove('eId');
    }
}

async function trackIdLayer() {
    const hId = Cookies.get('hEid');
    const hKid = Cookies.get('hKid');
    const hMail = Cookies.get('hMail');
    const eventName = Cookies.get('eName');
    if (hId) {
        await trackIdLayerComplete(hId, hMail, hKid);
        Cookies.remove('hEid');
        Cookies.remove('hKid');
        Cookies.remove('hMail');
    } else if (hMail) {
        await trackIdLayerReduced(hMail, eventName);
        Cookies.remove('hEid');
        Cookies.remove('hKid');
        Cookies.remove('hMail');
        Cookies.remove('eName');
    }
}
